import { useParams } from 'react-router-dom'

import Header from '../components/Header/SimpleHeader'
import Evse from '../components/Evse'
import Footer from '../components/Footer'

export default function EvsePage() {
  let params = useParams()

  return (
    <>
      <Header />
      <Evse code={params.code} />
      <Footer />
    </>
  )
}
