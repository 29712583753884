import { useTranslation } from 'react-i18next'
import { CheckCircleIcon } from '@heroicons/react/24/solid'

export default function StripeConfirmationPage() {
  const { t } = useTranslation()

  return (
    <main className="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8">
      <div className="flex flex-col justify-center items-center text-center">
        <CheckCircleIcon className="w-16 text-green-500" />
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-5xl">
          {t('stripe.title')}
        </h1>
        <p className="mt-6 text-base leading-7 text-gray-600 dark:text-gray-400">
          {t('stripe.subtitle')}
        </p>
      </div>
    </main>
  )
}
