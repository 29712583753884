import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isAndroid, isIOS } from 'react-device-detect'

import { LoadingIcon } from './Loading/LoadingIcon'

interface Props {
  code: string | undefined
}

export default function Evse({ code }: Props) {
  const { t } = useTranslation()
  const [willRedirect, setWillRedirect] = useState(true)

  const isMobile = isIOS || isAndroid

  const getStoreLink = (): string | undefined => {
    if (isIOS) return process.env.REACT_APP_APP_STORE_URL
    if (isAndroid) return process.env.REACT_APP_GOOGLE_PLAY_URL
    return undefined
  }

  const getAppLink = (): string | undefined => {
    if (isMobile) {
      return `foresta://explore/evses/${code}`
    }
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const url = getAppLink()
      if (url) window.location.href = url
    }, 2000)
    return () => clearTimeout(timeoutId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // disable store redirection
  /*useEffect(() => {
    const timeoutId = setTimeout(() => {
      const url = getStoreLink()
      if (url) window.location.href = url
      setWillRedirect(false)
    }, 5000)
    return () => clearTimeout(timeoutId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])*/

  return (
    <section className="bg-white/0 dark:bg-black py-10 sm:py-60">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 space-y-10">
        {isMobile && willRedirect ? (
          <div className="flex flex-col justify-center items-center gap-2">
            <h1 className="text-4xl font-bold tracking-tight text-slate-900 dark:text-slate-100 sm:text-6xl text-center">
              {t('loading')}
            </h1>
            <p>{t('redirecting')}</p>
            <div className="group flex items-center gap-2 mt-4">
              <div className="w-8 h-8">
                <LoadingIcon color="text-gray-500" />
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center gap-2">
            <h1 className="text-4xl font-bold tracking-tight text-slate-900 dark:text-slate-100 sm:text-6xl text-center">
              {t('home.title')}
            </h1>
            <p>{t('home.subtitle')}</p>
            <div className="mt-6">
              <a
                href="/home"
                className="rounded-md bg-brand px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-brand-dark focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand cursor-pointer"
              >
                {t('learnMore')}
              </a>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}
