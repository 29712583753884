import { useTranslation } from 'react-i18next'

import i18n from '../i18n'
import { Language } from '../types/Language'
import Phone from './Phone/Phone'
import GridShape from './Shapes/GridShape'

import screenshot1En from '../assets/img/screenshots/en/screenshot-1.png'
import screenshot2En from '../assets/img/screenshots/en/screenshot-2.png'
import screenshot3En from '../assets/img/screenshots/en/screenshot-3.png'
import screenshot4En from '../assets/img/screenshots/en/screenshot-4.png'

import screenshot1Fr from '../assets/img/screenshots/fr/screenshot-1.png'
import screenshot2Fr from '../assets/img/screenshots/fr/screenshot-2.png'
import screenshot3Fr from '../assets/img/screenshots/fr/screenshot-3.png'
import screenshot4Fr from '../assets/img/screenshots/fr/screenshot-4.png'

export default function ScreenShots() {
  const { t } = useTranslation()

  const screenshot1 = i18n.resolvedLanguage === Language.French ? screenshot1Fr : screenshot1En
  const screenshot2 = i18n.resolvedLanguage === Language.French ? screenshot2Fr : screenshot2En
  const screenshot3 = i18n.resolvedLanguage === Language.French ? screenshot3Fr : screenshot3En
  const screenshot4 = i18n.resolvedLanguage === Language.French ? screenshot4Fr : screenshot4En

  const screenshots = [
    {
      image: screenshot1,
      title: t('screenshots.1.title'),
      description: t('screenshots.1.description'),
    },
    {
      image: screenshot2,
      title: t('screenshots.2.title'),
      description: t('screenshots.2.description'),
    },
    {
      image: screenshot3,
      title: t('screenshots.3.title'),
      description: t('screenshots.3.description'),
    },
    {
      image: screenshot4,
      title: t('screenshots.4.title'),
      description: t('screenshots.4.description'),
    },
  ]

  return (
    <section className="relative isolate bg-white/0 dark:bg-slate-600/60 py-10 sm:py-16">
      <GridShape />
      <div className="mx-auto max-w-7xl px-6 lg:px-8 space-y-10">
        <h2 className="text-4xl font-bold tracking-tight text-slate-900 dark:text-slate-100 sm:text-6xl text-center">
          {t('screenshots.title')}
        </h2>
        <div className="flex flex-col md:flex-row gap-6">
          {screenshots.map((screenshot, index) => (
            <div
              key={`screenshot-${index}`}
              className="flex flex-col items-center text-center space-y-2"
            >
              <h3 className="text-xl text-slate-900 dark:text-slate-100">{screenshot.title}</h3>
              <div className="w-52">
                <Phone screenshot={screenshot.image} />
              </div>
              <p className="text-gray-500">{screenshot.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
