export default function UniversalLinksPage() {
  return (
    <section className="relative isolate bg-white/0 dark:bg-slate-600/60 py-10 sm:py-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 space-y-10">
        <div>
          <h2 className="text-4xl font-bold tracking-tight text-slate-900 dark:text-slate-100 sm:text-6xl">
            Universal Links
          </h2>
        </div>

        <ul className="space-y-2">
          <li>
            <a className="text-brand hover:text-brand-dark" href="foresta://foresta.energy/explore">
              foresta://foresta.energy/explore
            </a>
          </li>
          <li>
            <a
              className="text-brand hover:text-brand-dark"
              href="foresta://foresta.energy/dashboard"
            >
              foresta://foresta.energy/dashboard
            </a>
          </li>
          <li>
            <a className="text-brand hover:text-brand-dark" href="foresta://foresta.energy/host">
              foresta://foresta.energy/host
            </a>
          </li>
          <li>
            <a className="text-brand hover:text-brand-dark" href="foresta://foresta.energy/account">
              foresta://foresta.energy/account
            </a>
          </li>
          <li>
            <a
              className="text-brand hover:text-brand-dark"
              href="foresta://foresta.energy/explore/locations/6510b53c-69a9-4b3f-b116-2b64b03d0fd2"
            >
              foresta://foresta.energy/explore/locations/6510b53c-69a9-4b3f-b116-2b64b03d0fd2
            </a>
          </li>
          <li>
            <a
              className="text-brand hover:text-brand-dark"
              href="foresta://foresta.energy/dashboard/contracts/90b54840-4aa6-4b81-95e0-3601fd6eeaec"
            >
              foresta://foresta.energy/dashboard/contracts/90b54840-4aa6-4b81-95e0-3601fd6eeaec
            </a>
          </li>
        </ul>
      </div>
    </section>
  )
}
