import ReactDOM from 'react-dom/client'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'

import './i18n'
import './index.css'
import { ColorSchemeProvider } from './contexts/ColorSchemeContext'
import App from './pages/App'
import HomePage from './pages/Home'
import TermsConditionsPage from './pages/TermsConditions'
import PrivacyPolicyPage from './pages/PrivacyPolicy'
import HostEarningsPage from './pages/HostEarnings'
import FaqPage from './pages/Faq'
import UnsubscribePage from './pages/Unsubscribe'
import EvsePage from './pages/Evse'
import LocationPage from './pages/Location'
import ContactPage from './pages/Contact'
import StripeConfirmationPage from './pages/StripeConfirmation'
import UniversalLinksPage from './pages/UniversalLinks'
import NotFoundPage from './pages/NotFound'
import GuidelinesPage from './pages/Guidelines'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <ColorSchemeProvider>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="home" element={<HomePage />} />
        <Route path="terms-conditions" element={<TermsConditionsPage />} />
        <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="host-earnings" element={<HostEarningsPage />} />
        <Route path="faq" element={<FaqPage />} />
        <Route path="unsubscribe/:code" element={<UnsubscribePage />} />
        <Route path="evses/:code" element={<EvsePage />} />
        <Route path="locations/:id" element={<LocationPage />} />
        <Route path="contact" element={<ContactPage />} />
        <Route path="return" element={<StripeConfirmationPage />} />
        <Route path="guidelines" element={<GuidelinesPage />} />
        <Route path="universal-links" element={<UniversalLinksPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  </ColorSchemeProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
